import React, { useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light as lightBase, dark as darkBase, PancakeTheme } from 'polyquail-chk-uikit'

const CACHE_KEY = 'IS_DARK'

const ThemeContext = React.createContext({ isDark: null, toggleTheme: () => null })

const ThemeContextProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  })

  const toggleTheme = () => {
    setIsDark((prevState) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }

  // customize colors

  const light: PancakeTheme = lightBase

  // this is the background color
  light.colors.background = '#eeeddd'

  // disabled button color
  light.colors.backgroundDisabled = '#dcdcdc'

  // multiplier tag color
  light.colors.secondary = '#ad5723'

  // color on menu hover
  light.colors.tertiary = '#ad5723'

  // the big headers etc
  light.colors.primary = '#31190b'
  light.colors.primaryBright = '#31190b'
  light.colors.primaryDark = '#31190b'

  // color on the text on the farm card
  // this is also the color of the sub-menu
  light.colors.text = '#030000'

  // color on the menu
  light.colors.textSubtle = '#eeeddd'

  // this is the quail primary color, no change
  light.button.primary.background = '#ff9100'
  light.button.primary.backgroundActive = '#ff9100'
  light.button.primary.backgroundHover = '#ff9100'

  // this is the color on Paladin, RugDoc and Countdown card
  // also the cards on the home page
  light.card.background = '#f3c887'

  // TODO: change card color src -> theme -> colors -> lightColors

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? darkBase : light}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
