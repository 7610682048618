import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmsPublicDataAsync, fetchQuailFarmsPublicDataAsync } from './actions'
import { State, Farm, QuailFarm } from './types'
import { QuoteToken } from '../config/constants/types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchQuailFarmsPublicDataAsync())
    // dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

/*  ---------------------
          Quail Farms
    ---------------------*/
export const useQuailFarms = (): QuailFarm[] => {
  const quailFarms = useSelector((state: State) => state.quailFarms.data)
  // const quailFarms = []
  return quailFarms
}

export const useQuailFarmFromTsAddr = (tsAddr: string): QuailFarm => {
  const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
  const quailFarm = useSelector((state: State) => state.quailFarms.data.find((f) => f.tsAddress[CHAIN_ID] === tsAddr))
  // console.log(quailFarm);
  return quailFarm
}

export const useQuailFarmUser = (tsAddr: string) => {
  const quailFarm = useQuailFarmFromTsAddr(tsAddr)
  // console.log(quailFarm);

  return {
    allowance: quailFarm.userData
      ? new BigNumber(quailFarm.userData.allowance).div(new BigNumber(10).pow(quailFarm.stakeTokenDecimals))
      : new BigNumber(0),
    tokenBalance: quailFarm.userData
      ? new BigNumber(quailFarm.userData.tokenBalance).div(new BigNumber(10).pow(quailFarm.stakeTokenDecimals))
      : new BigNumber(0),
    stakedBalance: quailFarm.userData
      ? new BigNumber(quailFarm.userData.stakedBalance).div(new BigNumber(10).pow(quailFarm.stakeTokenDecimals))
      : new BigNumber(0),
    earnings: quailFarm.userData
      ? new BigNumber(quailFarm.userData.earnings).div(new BigNumber(10).pow(quailFarm.rewardTokenDecimals))
      : new BigNumber(0),
  }
}

/*  ---------------------
          Farms
    ---------------------*/
export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  // console.log(farms)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  // console.log(pid);

  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))

  // console.log(farm);

  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData
      ? new BigNumber(farm.userData.allowance).div(new BigNumber(10).pow(farm.tokenDecimals))
      : new BigNumber(0),
    tokenBalance: farm.userData
      ? new BigNumber(farm.userData.tokenBalance).div(new BigNumber(10).pow(farm.tokenDecimals))
      : new BigNumber(0),
    stakedBalance: farm.userData
      ? new BigNumber(farm.userData.stakedBalance).div(new BigNumber(10).pow(farm.tokenDecimals))
      : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings).div(new BigNumber(10).pow(18)) : new BigNumber(0),
  }
}

// Prices

export const usePriceWmaticUsdc = (): BigNumber => {
  const pid = 0 // USDC-WMATIC LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceCakeUsdc = (): BigNumber => {
  const pid = 8 // MYTOKEN-USDC LP
  const farm = useFarmFromPid(pid)

  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const wmaticPrice = usePriceWmaticUsdc()
  let value = new BigNumber(0)
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val
      if (farm.quoteTokenSymbol === QuoteToken.WMATIC) {
        val = wmaticPrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteTokenSymbol === QuoteToken.USDC) {
        val = farm.lpTotalInQuoteToken
      } else {
        val = farm.lpTotalInQuoteToken
      }
      value = value.plus(val)
    }
  }
  return value
}
