import contracts from './contracts'
import { QuailFarmConfig, QuoteToken } from './types'

const quailFarms: QuailFarmConfig[] = [
  // NOTE: ONLY USE USDC FOR THE QUOTE CURRENCY
  {
    tsAddress: {
      // address of the tokenStaking address
      137: '0x07E31a12c737F8Be7f24dA96F4CF13fA2bf9F1C3',
    },
    rewardLpSymbol: 'CHK-USDC',
    rewardLpAddress: {
      137: '0xc5915d6bc03ba9d4ee338ae83ae388b86cc0bac0', // CHK-USDC
    },
    rewardTokenSymbol: 'CHK',
    rewardTokenAddress: {
      137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa', // CHK
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,

    // TODO: change this for each new pool
    stakeTokenSymbol: 'CHK',
    stakeTokenAddress: {
      137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa', // CHK
    },
    stakeLpSymbol: 'CHK-USDC',
    stakeLpAddress: {
      137: '0xc5915d6bc03ba9d4ee338ae83ae388b86cc0bac0', // CHK-USDC
    },
  },

  {
    tsAddress: {
      // address of the tokenStaking address
      137: '0x6b272e4C6aEF4550dBA8D1804a70C0ad7866518c',
    },
    rewardLpSymbol: 'USDC-USDC',
    rewardLpAddress: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728', // USDT-USDC
    },
    rewardTokenSymbol: 'USDC',
    rewardTokenAddress: {
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,

    // TODO: change this for each new pool
    stakeTokenSymbol: 'CHK',
    stakeTokenAddress: {
      137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa', // CHK
    },
    stakeLpSymbol: 'CHK-USDC',
    stakeLpAddress: {
      137: '0xc5915d6bc03ba9d4ee338ae83ae388b86cc0bac0', // CHK-USDC
    },
  },

  {
    tsAddress: {
      // address of the tokenStaking address
      137: '0xE9e772761d05d033eC583278ed6dDe744407c6A6',
    },
    rewardLpSymbol: 'DAI-USDC',
    rewardLpAddress: {
      137: '0xf04adbf75cdfc5ed26eea4bbbb991db002036bdd', // DAI-USDC
    },
    rewardTokenSymbol: 'DAI',
    rewardTokenAddress: {
      137: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063', // DAI
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,

    // TODO: change this for each new pool
    stakeTokenSymbol: 'CHK',
    stakeTokenAddress: {
      137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa', // CHK
    },
    stakeLpSymbol: 'CHK-USDC',
    stakeLpAddress: {
      137: '0xc5915d6bc03ba9d4ee338ae83ae388b86cc0bac0', // CHK-USDC
    },
  },

  {
    tsAddress: {
      // address of the tokenStaking address
      137: '0xFD130F33FD3459abE2a55f72719d03C0E8D18D76',
    },
    rewardLpSymbol: 'USDT-USDC',
    rewardLpAddress: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728', // USDT-USDC
    },
    rewardTokenSymbol: 'USDT',
    rewardTokenAddress: {
      137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,

    // TODO: change this for each new pool
    stakeTokenSymbol: 'CHK',
    stakeTokenAddress: {
      137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa', // CHK
    },
    stakeLpSymbol: 'CHK-USDC',
    stakeLpAddress: {
      137: '0xc5915d6bc03ba9d4ee338ae83ae388b86cc0bac0', // CHK-USDC
    },
  },
]

export default quailFarms
