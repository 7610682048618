import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress } from 'utils/addressHelpers'
import farmsConfig from 'config/constants/farms'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchFarms = async () => {
  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
          name: 'balanceOf',
          params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP, // Balance of token in the LP contract, 511659365351909765233
        quoteTokenBlanceLP, // Balance of quote token on LP contract, 420877989498
        lpTokenBalanceMC, // Balance of LP tokens in the master chef contract, 12657076293375753
        lpTotalSupply, // Total supply of LP tokens, 13319155001490011
        tokenDecimals, // Token decimals, 18
        quoteTokenDecimals, // Quote token decimals, 6
      ] = await multicall(erc20, calls)

      /*
      if (farmConfig.pid === 9) {
        console.log('quoteTokenBlanceLP')
        console.log(quoteTokenBlanceLP)
        console.log('tokenBalanceLP')
        console.log(tokenBalanceLP)
      }
      */

      let tokenAmount // how many lp tokens the mc holds, in decimals
      let lpTotalInQuoteToken // value of total lp tokens
      let tokenPriceVsQuote
      if (farmConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
        if (farmConfig.tokenSymbol === QuoteToken.USDC && farmConfig.quoteTokenSymbol === QuoteToken.USDC) {
          // USDC - USDC
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          // lp usdc balance / lp token balance
          // give you token price, in decimals
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(tokenBalanceLP))
            .div(new BigNumber(10).pow(quoteTokenDecimals))
            .times(new BigNumber(10).pow(tokenDecimals))
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatio)

        if (tokenAmount.comparedTo(0) > 0) {
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP)
            .div(new BigNumber(tokenBalanceLP))
            .div(new BigNumber(10).pow(quoteTokenDecimals))
            .times(new BigNumber(10).pow(tokenDecimals))
        }
      }

      /* TODO 15: 
          update src/config/abi/masterchef.json
      */
      const [info, totalAllocPoint, tokenPerBlock] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          // TODO: replace CHKPerBlock 9
          name: 'CHKPerBlock',
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        tokenDecimals: tokenDecimals[0],
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP.toNumber(),
        tokenPerBlock: new BigNumber(tokenPerBlock).toNumber(),
      }
    }),
  )
  return data
}

export default fetchFarms
