import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 8,
    risk: 5,
    // TODO: change lpSymbol (replace 4)
    lpSymbol: 'CHK-USDC LP',
    lpAddresses: {
      // TODO: change lp address (replace 5)
      137: '0xc5915d6bc03ba9d4ee338ae83ae388b86cc0bac0',
    },
    // TODO: token symbol, (search replace 3)
    tokenSymbol: 'CHK',
    tokenAddresses: {
      137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa', // token
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'KEGG-USDC LP',
    lpAddresses: {
      137: '0x849d225f86d29a390c731dc8df77feec842a7569',
    },
    tokenSymbol: 'KEGG',
    tokenAddresses: {
      137: '0x4f219CfC1681c745D9558fd64d98373A21a246CA', // token
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 11,
    risk: 5,
    lpSymbol: 'KWIL-USDC LP',
    lpAddresses: {
      137: '0x6711deecabf6401c98effaecd552e8f4eab3f69f',
    },
    tokenSymbol: 'KWIL',
    tokenAddresses: {
      137: '0x252656AdC9E22C697Ce6c08cA9065FBEe5E394e7', // token
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 0,
    risk: 3,
    lpSymbol: 'WMATIC-USDC LP',
    lpAddresses: {
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
    },
    tokenSymbol: 'WMATIC',
    tokenAddresses: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 1,
    risk: 3,
    lpSymbol: 'USDC-USDT LP',
    lpAddresses: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728', // USDT-USDC
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  /* Token Farms */
  {
    pid: 9,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'CHK',
    lpAddresses: {
      137: '0xc5915d6bc03ba9d4ee338ae83ae388b86cc0bac0', // CHK-USDC
    },
    tokenSymbol: 'CHK',
    tokenAddresses: {
      137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa', // CHK v
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 12,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'KEGG',
    lpAddresses: {
      137: '0x849d225f86d29a390c731dc8df77feec842a7569', // KEGG-USDC
    },
    tokenSymbol: 'KEGG',
    tokenAddresses: {
      137: '0x4f219CfC1681c745D9558fd64d98373A21a246CA', // KEGG
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 10,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'KWIL',
    lpAddresses: {
      137: '0x6711deecabf6401c98effaecd552e8f4eab3f69f', // KWIL-USDC
    },
    tokenSymbol: 'KWIL',
    tokenAddresses: {
      137: '0x252656AdC9E22C697Ce6c08cA9065FBEe5E394e7', // KWIL
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'WMATIC',
    lpAddresses: {
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827', // MATIC-USDC
    },
    tokenSymbol: 'WMATIC',
    tokenAddresses: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', // MATIC v
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 3,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    lpAddresses: {
      137: '0xf6a637525402643b0654a54bead2cb9a83c8b498', // WBTC-USDC
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      137: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // WBTC v
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 4,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d', //  WETH-USDC
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // WETH v
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 5,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      137: '0xf04adbf75cdfc5ed26eea4bbbb991db002036bdd', // DAI-USDC
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      137: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063', // DAI v
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 6,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728', // USDT-USDC
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      137: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // USDT v
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 7,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      // 137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827', // MATIC-USDC
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // USDC v
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
]

export default farms
