import { configureStore } from '@reduxjs/toolkit'
import farmsReducer from './farms'
import quailFarmsReducer from './quailFarms'

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    farms: farmsReducer,
    quailFarms: quailFarmsReducer
  },
})
