import { MenuEntry } from 'polyquail-chk-uikit'

const config: MenuEntry[] = [
  {
    label: 'All Layers',
    icon: 'LayerIcon',
    items: [
      {
        label: 'Layer1-KWIL (finished)',
        href: 'https://kwil.polyquail.finance/',
      },
      {
        label: 'Layer2-KEGG (finished)',
        href: 'https://kegg.polyquail.finance/',
      },
      {
        label: 'Layer3-CHK (finished)',
        href: 'https://chk.polyquail.finance/',
      },
    ],
  },
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        // TODO 11
        label: 'Buy CHK',
        href: 'https://quickswap.exchange/#/swap?outputCurrency=0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa',
      },
      {
        // TODO 12
        label: 'Add CHK-USDC',
        href:
          'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa',
      },
    ],
  },
  {
    label: 'LP Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Token Farms',
    icon: 'PoolIcon',
    href: '/nests',
  },
  {
    label: 'Dividends',
    icon: 'VaultsIcon',
    href: '/quailfarms',
  },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Poocoin',
        // TODO: change token address
        href: 'https://polygon.poocoin.app/tokens/0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa',
      },
      {
        label: 'Arken',
        // TODO: change token address
        href: 'https://swap.arken.finance/tokens/polygon/0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa',
      },
      {
        label: 'Dex Guru',
        // TODO: change token address
        href: 'https://dex.guru/token/0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa-polygon',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',

        href: 'https://github.com/polyquail-finance/',
      },
      {
        label: 'Docs',
        href: 'https://polyquail-finance.gitbook.io/polyquail-finance/',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/polygon/defi/polyquail-finance',
      },
      {
        label: "ApeO'Clock",
        href: 'https://www.apeoclock.com/launch/polyquail-chick-farm-launch/',
      },
      {
        label: 'Paladin Audit',
        href: 'https://paladinsec.co/projects/polyquail-layer-3-chk/',
      },
      {
        label: 'VFAT-TOOLS',
        href: 'https://vfat.tools/polygon/polyquail_chk/',
      },
      {
        label: 'PolyPup UI',
        href: 'https://ui.polypup.finance/address/0x439e9be4618bfc5ebe9b7357d848f65d24a50dde',
      },
    ],
  },
]

export default config
