import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import tokenstakingABI from 'config/abi/tokenstaking.json'
import multicall from 'utils/multicall'
import quailFarmsConfig from 'config/constants/quailFarms'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchQuailFarmUserAllowances = async (account: string) => {
  
  const calls = quailFarmsConfig.map((quailFarmConfig) => {
    const tsAddress = quailFarmConfig.tsAddress[CHAIN_ID];
    const stakeTokenContractAddress = quailFarmConfig.stakeTokenAddress[CHAIN_ID]
    return { address: stakeTokenContractAddress, name: 'allowance', params: [account, tsAddress] }
  })

  const rawStakeTokenAllowances = await multicall(erc20ABI, calls)
  const parsedStakeTokenAllowances = rawStakeTokenAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedStakeTokenAllowances
}

export const fetchQuailFarmUserTokenBalances = async (account: string) => {
  const calls = quailFarmsConfig.map((quailFarmConfig) => {
    const lpContractAddress = quailFarmConfig.stakeTokenAddress[CHAIN_ID]
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchQuailFarmUserStakedBalances = async (account: string) => {
  const calls = quailFarmsConfig.map((quailFarmConfig) => {
    const tsAddress = quailFarmConfig.tsAddress[CHAIN_ID];
    return {
      address: tsAddress,
      name: 'userInfo',
      params: [account],
    }
  })

  const rawStakedBalances = await multicall(tokenstakingABI, calls)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchQuailFarmUserEarnings = async (account: string) => {

  const calls = quailFarmsConfig.map((quailFarmConfig) => {
    const tsAddress = quailFarmConfig.tsAddress[CHAIN_ID];
    return {
      address: tsAddress,
      name: 'pendingReward',
      params: [account],
    }
  })

  const rawEarnings = await multicall(tokenstakingABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}
