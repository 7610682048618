import React, { useContext } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import { usePriceCakeUsdc } from 'state/hooks'
import { Menu as UikitMenu } from 'polyquail-chk-uikit'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const cakePriceUsd = usePriceCakeUsdc()

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={false}
      toggleTheme={() => null}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config}
      priceLink="https://polygon.poocoin.app/tokens/0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa"
      {...props}
    />
  )
}

export default Menu
