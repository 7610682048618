export default {
  cake: {
    // TODO MY TOKEN (search replace 1)
    137: '0x6116A2A8Ea71890Cf749823Ee9DEC991930a9eEa',
  },
  masterChef: {
    // TODO MY Masterchef (search replace 2)
    137: '0x439E9BE4618bfC5Ebe9B7357d848F65D24a50dDE',
  },
  wmatic: {
    // WMATIC
    137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  },
  multiCall: {
    137: '0x941dC4660E9eF36D48e7e78e797bBc910131B0f2',
  },
  usdc: {
    // USDC
    137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  },
}
