import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import tokenstakingABI from 'config/abi/tokenstaking.json'
import multicall from 'utils/multicall'
import quailFarmsConfig from 'config/constants/quailFarms'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const fetchQuailFarms = async () => {
  const data = await Promise.all(
    quailFarmsConfig.map(async (quailFarmConfig) => {
      const rewardLpAddress = quailFarmConfig.rewardLpAddress[CHAIN_ID]
      const stakeLpAddress = quailFarmConfig.stakeLpAddress[CHAIN_ID]
      const calls = [
        // Balance of rewardToken in the LP contract (eg. BTC in BTC-USDC)
        {
          address: quailFarmConfig.rewardTokenAddress[CHAIN_ID],
          name: 'balanceOf',
          params: [rewardLpAddress],
        },
        // Balance of quote token on LP contract (eg. USDC in BTC-USDC)
        {
          address: quailFarmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [rewardLpAddress],
        },

        // Balance of stakeToken in the LP contract (eg. BTC in BTC-USDC)
        {
          address: quailFarmConfig.stakeTokenAddress[CHAIN_ID],
          name: 'balanceOf',
          params: [stakeLpAddress],
        },
        // Balance of quote token on LP contract (eg. USDC in BTC-USDC)
        {
          address: quailFarmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [stakeLpAddress],
        },

        // Balance of reward tokens in the tokenstaking contract (eg. BTC)
        {
          address: quailFarmConfig.rewardTokenAddress[CHAIN_ID],
          name: 'balanceOf',
          params: [quailFarmConfig.tsAddress[CHAIN_ID]],
        },
        {
          address: quailFarmConfig.stakeTokenAddress[CHAIN_ID],
          name: 'balanceOf',
          params: [quailFarmConfig.tsAddress[CHAIN_ID]],
        },

        // Reward Token decimals (eg. BTC decimals)
        {
          address: quailFarmConfig.rewardTokenAddress[CHAIN_ID],
          name: 'decimals',
        },
        // Stake Token decimals (eg. KWIL decimals)
        {
          address: quailFarmConfig.stakeTokenAddress[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals (eg. USDC decimals)
        {
          address: quailFarmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
      ]

      const [
        // reward LP pool
        rewardTokenBalanceLP,
        quoteRewardTokenBlanceLP,

        // stake LP pool
        stakeTokenBalanceLP,
        quoteStakeTokenBlanceLP,

        // TS balance
        rewardTokenBalanceTS, // Balance of reward tokens in the tokenstaking contract, 12657076293375753
        stakeTokenBalanceTS,

        // decimals
        rewardTokenDecimals, // Token decimals, 18
        stakeTokenDecimals, // Token decimals, 18
        quoteTokenDecimals, // Quote token decimals, 6
      ] = await multicall(erc20, calls)

      let rewardTokenPriceVsQuote
      // how many lp tokens the mc holds, in decimals
      const rewardTokenAmountTS = new BigNumber(rewardTokenBalanceTS).div(new BigNumber(10).pow(rewardTokenDecimals))

      if (
        quailFarmConfig.rewardTokenSymbol === QuoteToken.USDC &&
        quailFarmConfig.quoteTokenSymbol === QuoteToken.USDC
      ) {
        // USDC - USDC
        rewardTokenPriceVsQuote = new BigNumber(1)
      } else {
        // lp usdc balance / lp token balance
        // give you token price, in decimals
        rewardTokenPriceVsQuote = new BigNumber(quoteRewardTokenBlanceLP)
          .div(new BigNumber(rewardTokenBalanceLP))
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(new BigNumber(10).pow(rewardTokenDecimals))
      }
      // value of total lp tokens
      const rewardTotalValueTSInQuoteToken = rewardTokenAmountTS.times(rewardTokenPriceVsQuote)

      let stakeTokenPriceVsQuote
      // how many lp tokens the mc holds, in decimals
      const stakeTokenAmountTS = new BigNumber(stakeTokenBalanceTS).div(new BigNumber(10).pow(stakeTokenDecimals))

      if (
        quailFarmConfig.stakeTokenSymbol === QuoteToken.USDC &&
        quailFarmConfig.quoteTokenSymbol === QuoteToken.USDC
      ) {
        // USDC - USDC
        stakeTokenPriceVsQuote = new BigNumber(1)
      } else {
        // lp usdc balance / lp token balance
        // give you token price, in decimals
        stakeTokenPriceVsQuote = new BigNumber(quoteStakeTokenBlanceLP)
          .div(new BigNumber(stakeTokenBalanceLP))
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(new BigNumber(10).pow(stakeTokenDecimals))
      }
      // value of total lp tokens
      const stakeTotalValueTSInQuoteToken = stakeTokenAmountTS.times(stakeTokenPriceVsQuote)

      const [, rewardPerBlock, startBlock, bonusEndBlock] = await multicall(tokenstakingABI, [
        {
          address: quailFarmConfig.tsAddress[CHAIN_ID],
          name: 'poolInfo',
          params: [0],
        },
        {
          address: quailFarmConfig.tsAddress[CHAIN_ID],
          name: 'rewardPerBlock',
        },
        {
          address: quailFarmConfig.tsAddress[CHAIN_ID],
          name: 'startBlock',
        },
        {
          address: quailFarmConfig.tsAddress[CHAIN_ID],
          name: 'bonusEndBlock',
        },
      ])

      return {
        ...quailFarmConfig,

        rewardTokenAmountTS: rewardTokenAmountTS.toJSON(),
        rewardTokenDecimals: rewardTokenDecimals[0],

        stakeTokenAmountTS: stakeTokenAmountTS.toJSON(),
        stakeTokenDecimals: stakeTokenDecimals[0],
        // quoteTokenAmount: quoteTokenAmount,

        rewardTotalValueTSInQuoteToken: rewardTotalValueTSInQuoteToken.toJSON(),
        rewardTokenPriceVsQuote: rewardTokenPriceVsQuote.toJSON(),

        stakeTotalValueTSInQuoteToken: stakeTotalValueTSInQuoteToken.toJSON(),
        stakeTokenPriceVsQuote: stakeTokenPriceVsQuote.toJSON(),

        rewardPerBlock: new BigNumber(rewardPerBlock).toNumber(),
        startBlock: new BigNumber(startBlock).toString(),
        bonusEndBlock: new BigNumber(bonusEndBlock).toString(),
      }
    }),
  )
  return data
}

export default fetchQuailFarms
