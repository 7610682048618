export enum QuoteToken {
  'WMATIC' = 'WMATIC',
  'USDC' = 'USDC',
}

export interface Address {
  137: string
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  tokenSymbol: string
  tokenAddresses: Address
  quoteTokenSymbol: QuoteToken
  quoteTokenAdresses: Address
  multiplier?: string
  isTokenOnly?: boolean
  risk: number
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface QuailFarmConfig {
  tsAddress: Address
  rewardLpSymbol: string
  rewardLpAddress: Address
  rewardTokenSymbol: string
  rewardTokenAddress: Address
  stakeTokenSymbol: string
  stakeTokenAddress: Address
  quoteTokenSymbol: QuoteToken
  quoteTokenAdresses: Address
  stakeLpSymbol: string
  stakeLpAddress: Address
}
