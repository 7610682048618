/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import quailFarmsConfig from 'config/constants/quailFarms'
import fetchQuailFarms from './fetchQuailFarms'
import {
  fetchQuailFarmUserEarnings,
  fetchQuailFarmUserAllowances,
  fetchQuailFarmUserTokenBalances,
  fetchQuailFarmUserStakedBalances,
} from './fetchQuailFarmUser'
import { QuailFarmsState, QuailFarm } from '../types'

const initialState: QuailFarmsState = { data: [...quailFarmsConfig] }

const chainId = process.env.REACT_APP_CHAIN_ID

export const quailFarmsSlice = createSlice({
  name: 'QuailFarms',
  initialState,
  reducers: {
    setQuailFarmsPublicData: (state, action) => {
      const liveQuailFarmsData: QuailFarm[] = action.payload
      state.data = state.data.map((quailFarm) => {
        const liveQuailFarmData = liveQuailFarmsData.find((f) => f.tsAddress[chainId] === quailFarm.tsAddress[chainId])
        return { ...quailFarm, ...liveQuailFarmData }
      })
    },
    setQuailFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
    },
  },
})

// Actions
export const { setQuailFarmsPublicData, setQuailFarmUserData } = quailFarmsSlice.actions

// Thunks
export const fetchQuailFarmsPublicDataAsync = () => async (dispatch) => {
  const quailFarms = await fetchQuailFarms()
  dispatch(setQuailFarmsPublicData(quailFarms))
}

export const fetchQuailFarmUserDataAsync = (account) => async (dispatch) => {
  const userQuailFarmAllowances = await fetchQuailFarmUserAllowances(account)
  const userQuailFarmTokenBalances = await fetchQuailFarmUserTokenBalances(account)
  const userStakedBalances = await fetchQuailFarmUserStakedBalances(account)
  const userQuailFarmEarnings = await fetchQuailFarmUserEarnings(account)

  const arrayOfUserDataObjects = userQuailFarmAllowances.map((quailFarmAllowance, index) => {
    return {
      index,
      allowance: userQuailFarmAllowances[index],
      tokenBalance: userQuailFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userQuailFarmEarnings[index],
    }
  })

  dispatch(setQuailFarmUserData({ arrayOfUserDataObjects }))
}

export default quailFarmsSlice.reducer
